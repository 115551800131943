import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApilistService {
  baseUrl = environment.baseUrl

  login(body: any) {
    return this.http.post(this.baseUrl + `/v1/auth/login`, body)
  }

  forgotPassword(body: any) {
    return this.http.post(this.baseUrl + `/v1/auth/forgotPassword`, body)
  }

  updatePassword(body: any) {
    return this.http.post(this.baseUrl + `/v1/auth/resetPassword`, body)
  }

  sociallogin(body: any) {
    return this.http.post(this.baseUrl + `/v1/auth/socialLogin`, body)
  }

  signUp(body: any) {
    return this.http.post(this.baseUrl + `/v1/auth/signUp`, body)
  }

  verifyOtp(body: any) {
    return this.http.post(this.baseUrl + `/v1/auth/verifyOtp`, body)
  }

  resendOtp(body: any) {
    return this.http.post(this.baseUrl + `/v1/auth/resendOtp`, body)
  }

  createProfile(body: any) {
    return this.http.post(this.baseUrl + '/v1/user/createProfile', body)
  }

  getProfile(body: any) {
    return this.http.get(this.baseUrl + '/v1/user/getProfile', body)
  }

  uploadFile(body: any) {
    return this.http.post(this.baseUrl + '/v1/file/uploadSinglefile', body)
  }

  getDashboardData() {
    return this.http.get(this.baseUrl + `/v1/user/getDashboardData`)
  }

  searchBroker(body: any) {
    return this.http.get(this.baseUrl + `/v1/broker/search/${body}`)
  }

  // getSearchedBroker(body?:any){
  //   return this.http.get(this.baseUrl + `/v1/user/getMySearchedBroker?search&&offset&&limit`,body)

  // }

  getSearchedBroker(search?: any, offset?: number, limit?: number) {
    // Construct the query parameters
    const queryParams = new URLSearchParams();
    if (search !== undefined) {
      queryParams.set('search', search.toString());
    }
    if (offset !== undefined) {
      queryParams.set('offset', offset.toString());
    }

    if (limit !== undefined) {
      queryParams.set('limit', limit.toString());
    }

    // Construct the complete URL with query parameters
    const url = this.baseUrl + '/v1/user/getMySearchedBroker?' + queryParams.toString();

    // Make the HTTP GET request
    return this.http.get(url);
  }

  getRatingBrokers(offset?: number, limit?: number) {
    const queryParams = new URLSearchParams();
    if (offset !== undefined) {
      queryParams.set('offset', offset.toString());
    }

    if (limit !== undefined) {
      queryParams.set('limit', limit.toString());
    }
    const url = this.baseUrl + '/v1/broker/getFiveStarBroker?offset&&limit?' + queryParams.toString();
    return this.http.get(url);

  }

  mcNumber(body: any) {
    return this.http.post(this.baseUrl + '/v1/user/updateMcNumber', body)
  }

  addReport(body: any) {
    return this.http.post(this.baseUrl + '/v1/report/addReport', body)
  }

  getReportList() {
    return this.http.get(this.baseUrl + '/v1/report/getAllReports?offset&&limit')
  }

  getReportDetail(id: any) {
    return this.http.get(this.baseUrl + `/v1/report/getReport/${id}`)
  }

  addReview(body: any) {
    return this.http.post(this.baseUrl + '/v1/review/addReview', body)
  }

  updateReview(id: any, body: any) {
    return this.http.put(this.baseUrl + `/v1/review/updateReview/${id}`, body)
  }

  getRatingReview() {
    return this.http.get(this.baseUrl + '/v1/review/getMySubmittedReviews?offset&&limit')
  }

  // getReviewDetail(id: any) {
  //   return this.http.get(this.baseUrl + `/v1/review/getBrokerReviews/${id}?offset&&limit`)
  // }
  getReviewDetail(id: any, offset?: number, limit?: number) {
    let url = this.baseUrl + `/v1/review/getBrokerReviews/${id}`;
    if (offset !== undefined && limit !== undefined) {
      url += `?offset=${offset}&limit=${limit}`;
    } else if (offset !== undefined) {
      url += `?offset=${offset}`;
    }
    return this.http.get(url);
  }


  makeFavBroker(brokerId: any) {
    return this.http.get(this.baseUrl + `/v1/broker/favouiteUnfavourite/${brokerId}`)
  }

  getFavBroker() {
    return this.http.get(this.baseUrl + `/v1/user/getMyFavouriteBroker?offset&&limit`)
  }

  contactUs(body: any) {
    return this.http.post(this.baseUrl + '/v1/user/contactUs', body)
  }

  constructor(private http: HttpClient) { }
}
