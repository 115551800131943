<div class="container">
 <div class="bg-white radius20">
    <h4 class="textblack fw600 borderbottom p-3">
        About Us
    </h4>
    <p class="fs14 fw-normal px-3 pb-2 mb-0">Carrier Shield emerged from the firsthand experiences of witnessing
        the challenges truck drivers, owner-operators, and small carriers face in the logistics
        industry. As the prevalence of double brokers surged, it became evident that these hardworking
        professionals were often at a disadvantage, grappling with unscrupulous brokers who jeopardized
        their livelihoods. Our founding team recognized that the crux of the problem was the absence of
        accessible, affordable, and transparent information. In an industry where timely and accurate
        knowledge can mean the difference between profit and loss, many were left in the dark, making
        decisions based on limited or misleading information.
    </p>

    <p class="fs14 fw-normal px-3 pb-2 mb-0">Our core belief is that knowledge is power.By equipping drivers and
        carriers with comprehensive insights into broker histories,ratings,and reviews,we aim to level
        the playing field.Carrier Shield is not just a platform but a movement toward transparency and
        fairness.With every review shared,every rating given,and every report filed, our community grows
        stronger, ensuring that decisions are made based on real experiences and reliable data.
    </p>

    <p class="fs14 fw-normal px-3 pb-2 mb-0">We are driven by the stories of countless drivers and carriers who've
        faced setbacks due to bad brokers. Our mission extends beyond offering a service; it's about
        fostering a community where every member can thrive, make informed decisions, and protect their
        business interests. By centralizing information and making it readily available, we hope to
        empower our users, enabling them to navigate the complexities of the freight industry with
        confidence.
    </p>

    <p class="fs14 fw-normal px-3 pb-3 mb-0">As Carrier Shield continues to evolve, our commitment remains
        steadfast: to serve as a beacon of trust and reliability for the trucking community. We envision
        a future where every driver, owner-operator, and carrier has the tools and knowledge to drive
        their business forward, unencumbered by the shadows of deceitful brokers. Through collaboration,
        transparency, and empowerment, we are shaping a brighter, more equitable future for the
        logistics industry. 
    </p>
 </div>
</div>