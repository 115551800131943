import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guidevideo',
  templateUrl: './guidevideo.component.html',
  styleUrls: ['./guidevideo.component.scss']
})
export class GuidevideoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    location.reload()
  }

}
