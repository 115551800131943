import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutLandingPageComponent } from './pages/about-landing-page/about-landing-page.component';
import { ContactLandingPageComponent } from './pages/contact-landing-page/contact-landing-page.component';
import { PrivacyLandingPageComponent } from './pages/privacy-landing-page/privacy-landing-page.component';
import { TermConditionsLandingPageComponent } from './pages/term-conditions-landing-page/term-conditions-landing-page.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { GuidevideoComponent } from './pages/home/guidevideo/guidevideo.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule) },
  { path: 'verification-otp', loadChildren: () => import('./pages/verification-otp/verification-otp.module').then(m => m.VerificationOtpModule) },
  { path: 'createprofile', loadChildren: () => import('./pages/createprofile/createprofile.module').then(m => m.CreateprofileModule) },
  { path: 'about', component: AboutLandingPageComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'update-password/:id', component: UpdatePasswordComponent },
  { path: 'contact-us', component: ContactLandingPageComponent },
  { path: 'privacy', component: PrivacyLandingPageComponent },
  { path: 'term-conditions', component: TermConditionsLandingPageComponent },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  
  { path: '**', redirectTo: 'login' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
