<div class="container">
    <h4 class="textblack fw600 borderbottom p-3 mb-3">
    Contact Us
    </h4>
    <p class="fs14 fw-normal px-3 pb-2 mb-0">
        Have questions about signing up, searching for brokers, or how to share your experience?
        We're here to help you navigate the Carrier Shield platform and make the most of our
        community-powered tools. Whether you're dealing with a bad broker or want to commend
        a trustworthy one, your insights are valuable to us and the entire carrier community.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">
         Get In Touch:
    </h6>
    <p class="fs14 fw-normal px-3 pb-2 mb-0">If you need assistance or have suggestions on how we can improve, please
        don't hesitate to reach out.
        Our support team is committed to providing you with the protection and information you need to operate with
        confidence.
    </p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">
    Support Hours:
    </h6>
    <p class="fs14 fw-normal px-3 pb-2 mb-0">Our dedicated team is available to support you from Monday to Friday, 9 AM
        to 5 PM. We strive to respond to all
        inquiries within 48 hours.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">
    Contact Information:
    </h6>

    <p class="fs14 fw-normal px-3 pb-2 mb-0"><b>Email:</b> questions@mycarriershield.com </p>
    <p class="fs14 fw-normal px-3 pb-2 mb-0"><b>Address:</b> P.O. Box 1580, Van Buren, AR 72957</p>
    <p class="fs14 fw-normal px-3 pb-2 mb-0">We believe in fostering a strong community where every voice matters. Your
        feedback not only contributes to your
        success
        but also fortifies the integrity of the entire logistics network. Let's build a shield of trust together –
        because
        with Carrier Shield, knowledge truly is power.</p>
</div>