<section class="login_block">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 px-0">
        <div class="left_img d-flex align-items-center justify-content-end flex-column">
          <div class="w-75 mx-auto position-relative">
            <h1 class="text-white fw-500 pb-1 text-center">Protecting you from bad<br /> brokers</h1>
            <p class="text-white fs18 pb-5 text-center px-5">Free for drivers and carriers. no credit cards needed,
              ever!</p>
          </div>
        </div>
        <div class="logo_block">
          <img src="assets/images/logo_login.png" alt="" />
        </div>

      </div>
      <div class="col-md-6">
        <div class="login_wrap">
          <h3 class="text-dark fw-bold">Forgot Password</h3>
          <p class="fs16 mt-4 mb-0">It happens to all of us. Submit your email and we will send you instructions to reset
            your password.</p>
          <div class="bsForm mt-5 fontMed">
            <form [formGroup]="forgotPwdForm" class="row g-4">
              <div class="col-12">
                <label class="form-label">Email</label>
                <input type="email" formControlName="email" class="form-control" placeholder="Enter email">
                <div *ngIf="!forgotPwdForm.controls?.['email']?.valid && forgotPwdForm.controls?.['email']?.dirty">
                    <div
                      *ngIf="forgotPwdForm.controls?.['email']?.hasError('invalidEmail') && forgotPwdForm.controls?.['email']?.value"
                      class="error_message">Invalid email !</div>
                    <div class="error_message" *ngIf="forgotPwdForm.controls?.['email']?.hasError('required')">
                     Email is Mandatory !</div>
                  </div>
              </div>
   
              <div class="col-12">
                <button type="submit" (click)="submitData()" class="btn red_btn btn_xl rounded-pill w-100 commonBtn">Submit</button>
  
              </div>
            </form>
          <!-- </div> -->
        </div>
        </div>
      </div>

    </div>
  </div>
</section>

