<div class="container">
<div class="bg-white radius20">
    <h4 class="textblack fw600 borderbottom p-3 mb-3">Terms and Conditions</h4>
    <h6 class="textblack fw600 px-3 pb-0 mb-0">Acceptance of Terms:</h6>
    <p class="fs14 fw-normal py-1 px-3">By using Carrier Shield, you agree to the following terms and conditions. If you do not agree
        with these terms, please do not access or use our services.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">Registration:</h6>
    <p class="fs14 fw-normal py-1 px-3"> Users must register to access our services. You agree to provide accurate, current, and complete
        information during the registration process and update such information to keep it accurate.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">Content:</h6>
    <p class="fs14 fw-normal py-1 px-3"> Users are responsible for the content they post, including its legality, reliability, and
        appropriateness. Carrier Shield does not endorse or assume responsibility for any content
        provided by users.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">Prohibited Actions:</h6>
    <p class="fs14 fw-normal py-1 px-3">Users must not post content that is defamatory, offensive, or otherwise objectionable. Carrier
        Shield reserves the right to remove such content and may suspend or terminate users who
        violate this provision.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">Disclaimers:</h6>
    <p class="fs14 fw-normal py-1 px-3">Carrier Shield provides services on an "as-is" and "as available" basis. We make no guarantees
        regarding the accuracy, completeness, or reliability of any content.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">Limitation of Liability:</h6>
    <p class="fs14 fw-normal py-1 px-3">Carrier Shield shall not be liable for any indirect, incidental, or consequential damages arising
        out of the use or inability to use our services.</p>

    <h6 class="textblack fw600 px-3 pb-0 mb-0">Changes to Terms:</h6>
    <p class="fs14 fw-normal py-1 px-3"> We reserve the right to modify or replace these terms at any time. Continued use of our
        services a]er any such changes constitutes acceptance of the new terms.</p>

  </div>
</div>