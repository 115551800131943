<div class="bg-white radius20 mt-4 col-md-6">
            <div class="row">
                <div class="text-end position-relative video-full-img">
                    <img src="assets/images/video-img-full.png" alt=""/>
                    <div class="video_play">
                     <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#videoModal"><img src="assets/images/play_btn.svg" alt=""/></a>
                    </div>
                 </div>
            </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" (hidden.bs.modal)="close()"> 
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Guide Video</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <!-- <iframe width="100%" height="600" src="https://www.youtube.com/embed/UDPbJ4HTLl4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
              <video width="100%" height="600" controls>
                <source src="assets/video/IMG_2592.MP4" type="video/mp4">
            </video>
            </div>
          </div>
        </div>
      </div>
