import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApilistService } from 'src/app/services/api/apilist.service';
import { MessageService } from 'src/app/services/messageHandling/message.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  hide: boolean = true;
  show: boolean = true;
  changePasswordForm: FormGroup;
  id: any

  constructor(private formBuilder: FormBuilder, private api: ApilistService, private router: Router, public message: MessageService, public activeroute: ActivatedRoute,) {
    this.activeroute.params.subscribe((params: any,) => {
      this.id = params['id'];
    })
    

    this.changePasswordForm = this.formBuilder.group({
      password: ['',],
      cPassword: ['',],
    }, { validator: this.checkPasswords });
  }

  ngOnInit(): void {
  }

  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('cPassword')?.value
    if (pass != confirmPass) {
      control.get('cPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  hideShowPassword() {
    this.hide = !this.hide;
  }

  hideConfirmPassword() {
    this.show = !this.show;

  }

  submitData() {
    if (this.changePasswordForm.value.password != this.changePasswordForm.value.cPassword) {
      this.message.errorMessage('password and confirm password must be the same.','' ,2000)
    }
    else {
      let data={
        'password': this.changePasswordForm.value.password,
        'confirmPassword':this.changePasswordForm.value.cPassword,
        'forgotPasswordHash': this.id
      }
      this.api.updatePassword(data).subscribe((res: any) => {
        this.message.successMessage(res.message, '')
        this.router.navigate(['/login'])
      })
    }
  }

}

