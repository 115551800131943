<div class="container">
    <div class="bg-white radius20">
        <h4 class="textblack fw600 borderbottom p-3 mb-3">Privacy Policy</h4>
        <h6 class="textblack fw600 px-3 pb-0 mb-0">Collection of Information:</h6>
        <p class="fs14 fw-normal py-1 px-3">
            We collect information you provide directly to us, such as when you create an account, post a
            review, or communicate with us.</p>
        <h6 class="textblack fw600 px-3 pb-0 mb-0">Use of Information:</h6>
        <p class="fs14 fw-normal py-1 px-3">
            We use theinformation to provide, maintain, and improve our services. We may also use this
            information to send you technical notices, updates, and other relevant messages.</p>
        <h6 class="textblack fw600 px-3 pb-0 mb-0">Sharing of Information:</h6>
        <p class="fs14 fw-normal py-1 px-3">We do not share, sell, or disclose your personal information to third
            parties without your consent, except in specific circumstances like complying with legal
            obligations.
        </p>
        <h6 class="textblack fw600 px-3 pb-0 mb-3">Data Security:</h6>
        <p class="fs14 fw-normal py-1 px-3">We take reasonable measures to protect your personal information.
            However, no online service
            is entirely secure, so we cannot guarantee the absolute security of your information.</p>
        <h6 class="textblack fw600 px-3 pb-0 mb-0">Cookies:</h6>
        <p class="fs14 fw-normal py-1 px-3">We use cookies and similar tracking technologies to enhance your
            experience and gather
            information about visitors and visits to our services.
        </p>
        <h6 class="textblack fw600 px-3 pb-0 mb-0"> Changes to Privacy Policy:</h6>
        <p class="fs14 fw-normal py-1 px-3">We may revise this Privacy Policy from time to time. We will notify users
            of any changes by
            posting the new Privacy Policy on our platform.
        </p>
        <h6 class="textblack fw600 px-3 pb-0 mb-0">Contact Us:</h6>
        <p class="fs14 fw-normal py-1 px-3">For any questions about this Privacy Policy, don't hesitate to contact us.
        </p>
    </div>
</div>