<section class="login_block">
    <div class="container-fluid">
      <div class="row vh-100 justify-content-center  align-items-center">
 
        <div class="col-md-12 d-flex">
          <div class="login_wrap">
            <div class="logo_block pb-3">
              <img src="assets/images/logo_login.png" alt="" />
            </div>
            <h4 class="text-dark fw-bold text-center">Update Password</h4>
            <div class="bsForm mt-5 fontMed">
              <form [formGroup]="changePasswordForm" class="row g-4">
                <div class="col-12 input_withEye position-relative">
                    <label for="inputPassword4" class="form-label text-dark fs18 fw600">Password</label>
                    <input type="password" class="form-control" [type]="hide ? 'password' : 'text'"
                        formControlName="password" placeholder="Enter password" id="inputPassword4"
                        minlength="8" (keydown)="checkEvent($event)">
                    <mat-icon class="mttwo" (click)="hideShowPassword()">{{hide ? 'visibility_off' :
                        'visibility'}}</mat-icon>
                    <div *ngIf="!changePasswordForm.get('password')?.valid && changePasswordForm.get('password')?.dirty"
                        class="error_main_div">
                        <div *ngIf="changePasswordForm.get('password')?.hasError('required')"
                            class="error_message">This field is
                            Mandatory !</div>
                    </div>
                    <div *ngIf="changePasswordForm.get('password')?.hasError('minlength')"
                        class="error_main_div">
                        <div class="error_message">Password should be at least 8 characters long!</div>
                    </div>
                </div>
                <div class="col-12 input_withEye position-relative">
                    <label for="inputPassword4" class="form-label text-dark fs18 fw600"> Confirm Password</label>
                        <input type="password" class="form-control" placeholder="Enter confirm password" [type]="show ? 'password' : 'text'"
                            formControlName="cPassword" (keydown)="checkEvent($event)">
                        <mat-icon class="" (click)="hideConfirmPassword()">{{show ? 'visibility_off' :
                            'visibility'}}</mat-icon>
                        <div *ngIf="!changePasswordForm.get('cPassword')?.valid && changePasswordForm.get('cPassword')?.dirty"
                            class="error_main_div">
                            <div *ngIf="changePasswordForm.get('cPassword')?.invalid && changePasswordForm.get('cPassword')?.errors?.['ConfirmPassword']"
                                class="error_message">Password and confirm password is not match !</div>
                            <div *ngIf="changePasswordForm.get('cPassword')?.hasError('required')"
                                class="error_message">This field
                                is Mandatory !</div>
                        </div>
                </div>
                <div class="col-12">
                    <button type="submit" (click)="submitData()"
                        class="btn red_btn btn_xl rounded-pill w-100 commonBtn">Save</button>
                </div>
            </form>
            <!-- </div> -->
          </div>
          </div>
        </div>
  
      </div>
    </div>
  </section>
  
  