
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from '../messageHandling/message.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public message:MessageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
       
        switch (error.error.statusCode) {
          case 400:
            /********** handle error msg **********/
            console.log("hello")
            this.message.errorMessage(error.error.message, 'OK',60000)
            break;
          // case 0:
          //   /********** If server dosent respond **********/
          //   this.message.errorMessage(error.error.error, 2000)
          //   break;
          default:
            /********** Check for other serve-side errors **********/
            this.message.errorMessage(error.error.message, 'OK',6000)
            break;
        }
      }

    }));
  // }

  // this.spinner.show();
  // return next.handle(request).pipe(catchError((err: any){
  //   // switch (error.status) {
  //   //   case 401:
  //   //     /********** Auto logout if 401 response returned from api **********/
  //   //     localStorage.clear()
  //   //     this.message.errorMessage('You have been logged out for security purpose. Please login again.', 2000)
  //   //     this.router.navigate(['/'])
  //   //     break;
  //   //   case 400:
  //   //     /********** handle error msg **********/
  //   //     this.message.errorMessage(error.error.error, 2000)
  //   //     break;
  //   //   case 0:
  //   //     /********** If server dosent respond **********/
  //   //     this.message.errorMessage(error.error.error, 2000)
  //   //     break;
  //   //   default:
  //   //     /********** Check for other serve-side errors **********/
  //   //     this.message.errorMessage(error.error.error, 2000)
  //   //     break;
  //   // }
  //   //   return throwError(error);
  // }));
}

}

