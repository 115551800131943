import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './services/interceptor/error.interceptor';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { Ng2TelInputModule } from 'ng2-tel-input';
import {MatIconModule} from '@angular/material/icon';
import { SnackbarComponent } from './services/snackbar/snackbar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderInterceptor } from './services/interceptor/loader.interceptors';
import { AboutLandingPageComponent } from './pages/about-landing-page/about-landing-page.component';
import { PrivacyLandingPageComponent } from './pages/privacy-landing-page/privacy-landing-page.component';
import { TermConditionsLandingPageComponent } from './pages/term-conditions-landing-page/term-conditions-landing-page.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { GuidevideoComponent } from './pages/home/guidevideo/guidevideo.component';


@NgModule({
  declarations: [
    AppComponent,
    SnackbarComponent,
    PrivacyLandingPageComponent,
    TermConditionsLandingPageComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    GuidevideoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSnackBarModule,
    Ng2TelInputModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    ReactiveFormsModule,
    NgxSpinnerModule,

  ],
  providers: [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
